import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'

import { Layout } from '../components/commons'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Flex, Box, Heading, Text, Button } from '../atoms'

const NotFoundPage: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>Not found | Archera</title>
      </Helmet>

      <Box pt={[120, 160, 180]} mb={[80, 120, 160]}>
        <Grid>
          <Row center={'xs'}>
            <Col xs={12} sm={8}>
              <Heading as={'h2'} textAlign={'center'}>
                Not Found
              </Heading>
              <Box mt={4}>
                <Text textAlign={'center'} color={'darkShade'}>
                  We couldn’t find what you were looking for.
                </Text>
              </Box>
              <Flex justifyContent={'center'} mt={6}>
                <Link to='/'>
                  <Button label={'Go home'} buttonType={'purpleBlue'} />
                </Link>
              </Flex>
            </Col>
          </Row>
        </Grid>
      </Box>
    </Layout>
  )
}

export default NotFoundPage
